<template>
  <v-container v-if="!_.isEmpty(userEditable)">
    <!-- Banner header -->
    <Banner :title="'Edita tus datos de usuario'" />

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditAdmin" v-model="formEditUserValid">
          <v-text-field v-model="userEditable.role" label="Rol de administrador" readonly />
          <v-text-field v-model="userEditable.first_name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="userEditable.last_name" label="Apellidos" :rules="formRules.textRules" :readonly="!editable" />
          <CountryAutocomplete
            v-if="userEditable.role !== 'TECHNICIAN'"
            :countryId="userEditable.country"
            :editable="editable"
            @selected="userEditable.country = $event"
          />
          <v-text-field
            v-if="userEditable.role !== 'TECHNICIAN'"
            v-model="userEditable.email"
            label="Email"
            :rules="formRules.emailRules"
            :readonly="!editable"
          />
          <v-text-field v-model="userEditable.phone" label="Teléfono" :rules="formRules.phoneRules" :readonly="!editable" />
          <v-text-field
            v-if="userEditable.role !== 'TECHNICIAN'"
            v-model="userEditable.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            label="Contraseña"
            :rules="formRules.passwordNotRequiredRules"
            :readonly="!editable"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete";

export default {
  name: "Profile",
  components: {
    Banner,
    CountryAutocomplete
  },
  mixins: [FormRulesMixin, FormatDateMixin],
  data() {
    return {
      // Form edit admin
      editable: false,
      formEditUserValid: false,
      userEditable: {},
      showPassword: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      dbUser: (state) => state.user.dbUser
    }),
    _() {
      return _;
    }
  },
  watch: {
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    if (this.user.user_id !== this.$route.params.id) this.$router.push("/"); // Push back if id does not exist
    await this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    async formatFields() {
      await this.$store.dispatch("user/getDbUserInfo");
      this.userEditable = this._.cloneDeep(this.dbUser);
    },

    // Edit admin
    async edit() {
      if (this.$refs.formEditAdmin.validate()) {
        const userEditableFormattedData = {
          id: this.userEditable.id,
          first_name: this.userEditable.first_name,
          last_name: this.userEditable.last_name,
          email: this.userEditable.email,
          phone: this.userEditable.phone,
          country: this.userEditable.country
        };
        if (this.userEditable.password) userEditableFormattedData.password = this.userEditable.password;

        await this.$store.dispatch("user/editUser", userEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    }
  }
};
</script>
